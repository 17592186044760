/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f4f4f4;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #e0e0e0; /* Light gray background */
  height: 60px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.logo-container {
  height: 150px; /* Set your desired height */
  display: flex;
  align-items: center;
}

.app-logo {
  height: 100%; /* Make the image fill the container's height */
  width: auto;
  object-fit: contain;
}


.app-header h1 {
  font-size: 1.5rem;
}

.dark-mode-toggle {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-mode-toggle:hover {
  transform: scale(1.1);
}

.logo-container {
  transition: transform 0.2s ease;
}

.logo-container:hover {
  transform: scale(1.05);
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .app-header {
    height: 50px; /* Slightly smaller on mobile */
  }
  
  .logo-container {
    height: 80px; /* Smaller logo on mobile */
  }
  
  .app-logo {
    max-width: 150px; /* Adjust for mobile */
  }

  .app-header h1 {
    font-size: 1.2rem;
  }

  .message-content {
    max-width: 85%;
  }

  .welcome-container h2 {
    font-size: 1.5rem;
  }

  .suggested-prompts {
    grid-template-columns: 1fr;
  }

  .message {
    max-width: 90%;
  }
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: hidden;
}

.chat-window {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  /* Set a fixed height or min/max height if necessary */
  /* height: 500px; */ /* Optional: Define a specific height */
  /* min-height: 400px; */
  /* max-height: 800px; */
}

.empty-chat {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #888;
  font-style: italic;
}

.message {
  display: flex;
  max-width: 60%;
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  animation: fadeIn 0.3s ease-in-out;
}

.message.user {
  align-self: flex-end;
  flex-direction: row-reverse;
}

.message-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  flex-shrink: 0;
}

.message.assistant .message-avatar {
  background-color: #f0f0f0;
  color: #333;
}

.message.user .message-avatar {
  background-color: #0084ff;
  color: white;
}

.message-content {
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  background-color: #f0f0f0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.message.user .message-content {
  background-color: #0084ff;
  color: white;
}

.message-content p {
  margin-bottom: 10px;
}

.message-content h1, .message-content h2, .message-content h3, 
.message-content h4, .message-content h5, .message-content h6 {
  margin-top: 15px;
  margin-bottom: 10px;
}

.message-content ul, .message-content ol {
  margin-left: 20px;
  margin-bottom: 10px;
}

.message-content pre {
  background-color: #f4f4f4;
  border-radius: 4px;
  padding: 10px;
  overflow-x: auto;
}

.message-content code {
  background-color: #f4f4f4;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
}

.message-content blockquote {
  border-left: 4px solid #ccc;
  margin-left: 0;
  padding-left: 10px;
  color: #666;
}

.message-content table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1em;
  overflow: auto;
}

.message-content th,
.message-content td {
  border: 1px solid #ddd;
  padding: 0.75em;
  text-align: left;
}

.message-content th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.message-content tr:nth-child(even) {
  background-color: #fafafa;
}

.dark-mode .message-content table {
  border-color: #555;
}

.dark-mode .message-content th,
.dark-mode .message-content td {
  border-color: #555;
}

.dark-mode .message-content th {
  background-color: #444;
}

.dark-mode .message-content tr:nth-child(even) {
  background-color: #333;
}

.message-content img {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

 /* Indicates clickable image if using links */
}

.message-content img:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Typing Indicator and Text Animation */
.typing-indicator-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.thinking-text {
  font-size: 0.9rem;
  color: #666;
  opacity: 0.8;
  animation: fadeInOut 1s ease-in-out infinite;
}

.dark-mode .thinking-text {
  color: #aaa;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.4; }
  50% { opacity: 1; }
}

/* Update existing typing indicator styles */


.dark-mode .typing-indicator span {
  background-color: #aaa;
}

.typing-indicator {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
}

.typing-indicator span {
  width: 5px;
  height: 5px;
  margin: 0 1px;
  background-color: #888;
  border-radius: 50%;
  display: inline-block;
  animation: blink 1.4s infinite both;
}


.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

.message.assistant .typing-indicator span {
  background-color: #555;
}

@keyframes blink {
  0% { opacity: 0.2; }
  20% { opacity: 1; }
  100% { opacity: 0.2; }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Chat Form */
.chat-form {
  display: flex;
  padding: 1rem;
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0;
}

.chat-form input {
  flex-grow: 1;
  padding: 0.75rem 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 2rem;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.chat-form input:focus {
  outline: none;
  border-color: #2a5298;
}

.chat-form button {
  margin-left: 0.5rem;
  padding: 0.75rem;
  background-color: #2a5298;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.chat-form button:hover {
  background-color: #1e3c72;
  transform: scale(1.05);
}

.chat-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.clear-chat-btn {
  background-color: #dc3545 !important;
}

.clear-chat-btn:hover {
  background-color: #c82333 !important;
}

/* Welcome Screen */
.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 2rem;
}

.welcome-container h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #2a5298;
}

.welcome-container p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.suggested-prompts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  width: 100%;
  max-width: 800px;
}

.suggested-prompts button {
  background-color: #f0f0f0;
  border: none;
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.suggested-prompts button:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
}

/* Dark Mode Styles */
body.dark-mode {
  background-color: #1a1a1a;
  color: #f4f4f4;
}

.dark-mode .app-header {
  background-color: #333;
}

.dark-mode .message-content {
  background-color: #333;
  color: #f4f4f4;
}

.dark-mode .message.user .message-content {
  background-color: #0066cc;
}

.dark-mode .message.assistant .message-avatar {
  background-color: #444;
  color: #f4f4f4;
}

.dark-mode .chat-form {
  background-color: #222;
  border-top-color: #444;
}

.dark-mode .chat-form input {
  background-color: #333;
  color: #f4f4f4;
  border-color: #555;
}

.dark-mode .chat-form input:focus {
  border-color: #4a90e2;
}

.dark-mode .welcome-container h2 {
  color: #4a90e2;
}

.dark-mode .suggested-prompts button {
  background-color: #333;
  color: #f4f4f4;
}

.dark-mode .suggested-prompts button:hover {
  background-color: #444;
}

.dark-mode .message-content pre,
.dark-mode .message-content code {
  background-color: #2d2d2d;
  color: #f4f4f4;
}

.dark-mode .message-content blockquote {
  border-left-color: #555;
  color: #bbb;
}

.dark-mode .message-content th, 
.dark-mode .message-content td {
  border-color: #555;
}

/* Image Hover Effect */
.message-content img:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}
@media (max-width: 480px) {
  .message-content img {
    max-height: 200px; /* Further reduce height on very small screens */
  }

  .chat-form input {
    padding: 0.5rem 0.75rem;
  }

  .chat-form button {
    padding: 0.5rem;
  }
}
.modal-image {
  max-width: 100%;
  max-height: 80vh;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
}

/* Add to your styles.css */

.app-header {
  position: relative;
  z-index: 50;
}



/* Company List CSS */





/* Company List Button Styles */
.company-list-button {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0;
  border: none;
  transition: all 0.3s ease;
  height: fit-content;
}

.company-list-button .icon-wrapper {
  background: #4785FF;
  padding: 0.35rem;
  border-radius: 8px;
  color: white;
  box-shadow: 0 4px 12px rgba(71, 133, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-list-button .button-text {
  color: #4785FF;
  font-size: 0.9rem;
  font-weight: 600;
}

.company-list-button:hover .icon-wrapper {
  transform: translateY(-1px);
  box-shadow: 0 6px 16px rgba(71, 133, 255, 0.35);
}

.dark-mode .company-list-button .icon-wrapper {
  background: #4785FF;
  box-shadow: 0 4px 12px rgba(71, 133, 255, 0.15);
}

.dark-mode .company-list-button .button-text {
  color: #4785FF;
}



@media (max-width: 640px) {
  .company-list-button .button-text {
    display: none;
  }
}/* Company List Button Styles */
.company-list-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0;
  border: none;
  transition: all 0.3s ease;
}

.company-list-button .icon-wrapper {
  background: #4785FF;
  padding: 0.5rem;
  border-radius: 12px;
  color: white;
  box-shadow: 0 6px 16px rgba(71, 133, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-list-button .button-text {
  color: #4785FF;
  font-size: 1.1rem;
  font-weight: 600;
}

.company-list-button:hover .icon-wrapper {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(71, 133, 255, 0.35);
}

.dark-mode .company-list-button .icon-wrapper {
  background: #4785FF;
  box-shadow: 0 6px 16px rgba(71, 133, 255, 0.15);
}

.dark-mode .company-list-button .button-text {
  color: #4785FF;
}

@media (max-width: 640px) {
  .company-list-button .button-text {
    display: none;
  }
}/* Company List Button Styles */
.company-list-button {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0;
  border: none;
  transition: all 0.3s ease;
}

.company-list-button .icon-wrapper {
  background: #4785FF;
  padding: 0.75rem;
  border-radius: 16px;
  color: white;
  box-shadow: 0 8px 24px rgba(71, 133, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-list-button .button-text {
  color: #4785FF;
  font-size: 1.75rem;
  font-weight: 600;
}

.company-list-button:hover .icon-wrapper {
  transform: translateY(-2px);
  box-shadow: 0 12px 28px rgba(71, 133, 255, 0.35);
}

.dark-mode .company-list-button .icon-wrapper {
  background: #4785FF;
  box-shadow: 0 8px 24px rgba(71, 133, 255, 0.15);
}

.dark-mode .company-list-button .button-text {
  color: #4785FF;
}

@media (max-width: 640px) {
  .company-list-button .button-text {
    display: none;
  }
}/* Company List Styles */

.modal-content {
  max-width: 95vw !important;
  width: 1800px !important;
  min-height: 90vh;
  margin: 0 auto;
  background: #ffffff !important;
}

.dark-mode .modal-content {
  background: rgba(17, 24, 39, 0.95) !important;
  backdrop-filter: blur(10px);
}

.company-list-container {
  display: flex;
  gap: 2rem;
  padding: 1.5rem;
  height: 100%;
  color: #1e293b;
}

.dark-mode .company-list-container {
  color: #f3f4f6;
}

/* Title Styles */
.title-container {
  position: relative;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

.title-main {
  font-size: 2rem;
  font-weight: 800;
  background: linear-gradient(135deg, #3b82f6 0%, #60a5fa 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.title-main .icon {
  background: linear-gradient(135deg, #3b82f6 0%, #60a5fa 100%);
  padding: 0.5rem;
  border-radius: 12px;
  color: white;
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

.title-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #3b82f6 0%, transparent 100%);
}

.dark-mode .title-container::after {
  background: linear-gradient(to right, #60a5fa 0%, transparent 100%);
}

.title-subtitle {
  color: #64748b;
  font-size: 0.875rem;
}

.dark-mode .title-subtitle {
  color: #94a3b8;
}

/* Refresh Button Styles */
.refresh-button {
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.2);
}

.refresh-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(59, 130, 246, 0.3);
}

.refresh-button:active {
  transform: translateY(0);
}

.dark-mode .refresh-button {
  background: rgba(59, 130, 246, 0.8);
}

.dark-mode .refresh-button:hover {
  background: rgba(59, 130, 246, 1);
}

.dark-mode .refresh-button:disabled {
  background: rgba(156, 163, 175, 0.5);
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

/* Sidebar Styles */
.sidebar {
  width: 320px;
  flex-shrink: 0;
}

.main-content {
  flex: 1;
  min-width: 0;
  overflow-y: auto;
  padding-right: 1rem;
}

/* Stats Card Styles */
.stats-card {
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  transition: all 0.3s ease;
  padding: 1rem;
  border-radius: 1rem;
}

.dark-mode .stats-card {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.stats-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.stats-number {
  font-size: 1.5rem;
  font-weight: 700;
  color: #3b82f6;
}

.dark-mode .stats-number {
  background: linear-gradient(45deg, #3b82f6, #60a5fa);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Filter Section Styles */
.filter-section {
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 1rem;
  padding: 1.25rem;
  margin-bottom: 1.25rem;
}

.dark-mode .filter-section {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.filter-section h3 {
  color: #64748b;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
}

.dark-mode .filter-section h3 {
  color: #94a3b8;
}

/* Input and Select Styles */
.filter-input {
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 0.75rem;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  color: #1e293b;
  transition: all 0.2s ease;
}

.dark-mode .filter-input {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #f3f4f6;
}

.filter-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.25);
}

/* Document Card Styles */
.document-card {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 1rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
}

.dark-mode .document-card {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.document-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  border-color: #3b82f6;
}

/* Grid Layout */
.documents-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 1.5rem;
}

/* Filter Tags */
.filter-tag {
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  background: #eff6ff;
  border-radius: 9999px;
  font-size: 0.875rem;
  color: #3b82f6;
  margin: 0.25rem;
  transition: all 0.2s ease;
}

.dark-mode .filter-tag {
  background: rgba(59, 130, 246, 0.1);
  color: #60a5fa;
}

.filter-tag:hover {
  background: #dbeafe;
}

.dark-mode .filter-tag:hover {
  background: rgba(59, 130, 246, 0.15);
}

.filter-tag .remove-icon {
  margin-left: 0.5rem;
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-tag:hover .remove-icon {
  opacity: 1;
}

/* Scrollbar Styles */
.main-content::-webkit-scrollbar {
  width: 6px;
}

.main-content::-webkit-scrollbar-track {
  background: transparent;
}

.main-content::-webkit-scrollbar-thumb {
  background-color: #e2e8f0;
  border-radius: 3px;
}

.dark-mode .main-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Loading State Styles */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  text-align: center;
  padding: 2rem;
}

.loading-icon {
  width: 80px;
  height: 80px;
  border: 3px solid #e2e8f0;
  border-top-color: #3b82f6;
  border-radius: 50%;
  animation: loading-spin 1s linear infinite;
  margin-bottom: 2rem;
}

.dark-mode .loading-icon {
  border-color: rgba(255, 255, 255, 0.1);
  border-top-color: #60a5fa;
}

@keyframes loading-spin {
  to { transform: rotate(360deg); }
}

.loading-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  background: linear-gradient(135deg, #3b82f6 0%, #60a5fa 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.loading-subtitle {
  color: #64748b;
  max-width: 400px;
  margin: 0 auto;
}

.dark-mode .loading-subtitle {
  color: #94a3b8;
}

.loading-progress {
  margin-top: 2rem;
  width: 200px;
  height: 6px;
  background: #e2e8f0;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.dark-mode .loading-progress {
  background: rgba(255, 255, 255, 0.1);
}

.loading-progress::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 100%;
  background: linear-gradient(135deg, #3b82f6 0%, #60a5fa 100%);
  animation: loading-progress 1.5s ease-in-out infinite;
}

@keyframes loading-progress {
  0% { left: -30%; }
  100% { left: 100%; }
}

/* Text colors for light/dark mode */
.text-label {
  color: #64748b;
}

.dark-mode .text-label {
  color: #94a3b8;
}

.text-value {
  color: #1e293b;
}

.dark-mode .text-value {
  color: #f3f4f6;
}


/* Loading Thread */
.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f4f4f4;
}

.loading-icon {
  width: 80px;
  height: 80px;
  border: 5px solid #e0e0e0;
  border-top: 5px solid #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2a5298;
}

.loading-subtitle {
  font-size: 1rem;
  color: #64748b;
}




/* Add to your existing styles.css */
:root {
  --primary-color: #3b82f6;
  --primary-dark: #2563eb;
  --text-light: #e2e8f0;
  --text-dark: #1a202c;
  --background-light: #f7fafc;
  --background-dark: #1a1a2e;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark-mode {
  --primary-color: #60a5fa;
  --primary-dark: #3b82f6;
  --text-light: #1a202c;
  --text-dark: #e2e8f0;
  --background-light: #1a1a2e;
  --background-dark: #f7fafc;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

button:focus {
  outline: none;
}

.app-container {
  min-height: 100vh;
}


.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 60px); /* Adjust based on your header height */
}

.loading-screen {
  position: fixed;
  inset: 0;
  background: linear-gradient(to bottom right, #1a1a2e, #1e293b);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.03);
  padding: 3rem 4rem;
  border-radius: 1rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.loading-icon {
  width: 60px;
  height: 60px;
  border: 4px solid rgba(96, 165, 250, 0.1);
  border-left-color: #60a5fa;
  border-radius: 50%;
  margin-bottom: 2rem;
  box-shadow: 0 0 20px rgba(96, 165, 250, 0.2);
}

.loading-title {
  font-size: 1.75rem;
  font-weight: bold;
  color: #60a5fa;
  margin-bottom: 0.75rem;
  text-shadow: 0 0 10px rgba(96, 165, 250, 0.3);
}

.loading-subtitle {
  color: #94a3b8;
  font-size: 1.1rem;
}



/* Chat widget */
.chat-widget-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background: #3b82f6;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.5);
  z-index: 9999;
  transition: all 0.3s ease;
}

.chat-widget-button:hover {
  background: #2563eb;
  transform: scale(1.1);
}

.chat-widget-button:active {
  transform: scale(0.95);
}

.widget-icon {
  animation: bounce 2s infinite ease-in-out;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}
/* Add to your styles.css */
.html-visualization-container {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
  min-height: 400px;
}

.dark-mode .html-visualization-container {
  background-color: #1a1a1a;
  border: 1px solid #333;
}

.plotly-visualization-container {
  width: 100% !important;
  height: 100% !important;
  min-height: 400px;
}

/* Ensure Plotly graphs are responsive */
.js-plotly-plot, .plot-container {
  width: 100% !important;
}

.plotly-graph-div {
  width: 100% !important;
  height: 100% !important;
}

/* Dark mode adjustments for Plotly */
.dark-mode .js-plotly-plot .plotly {
  background-color: #1a1a1a;
  color: #ffffff;
}

.dark-mode .js-plotly-plot .plotly .main-svg {
  background: #1a1a1a !important;
}

/* Message content adjustments */
.message-content {
  max-width: 90% !important; /* Allow more width for visualizations */
}

@media (max-width: 768px) {
  .message-content {
    max-width: 95% !important;
  }
  
  .html-visualization-container {
    min-height: 300px;
  }
}