body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }
  
  body {
    @apply bg-gray-900 text-white antialiased;
  }
}

@layer components {
  .glass-effect {
    @apply bg-white/10 backdrop-blur-lg border border-white/20;
  }
  
  .gradient-text {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-secondary-400;
  }
}

@layer utilities {
  .scrollbar-hidden {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
}