

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    background-color: #fff;
    padding: 1rem;
    border-radius: 8px;
    overflow: auto;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
    color: #333;
  }
  
  .modal-close:hover {
    color: #ff0000;
  }
  
  /* Dark Mode Styles */
  body.dark-mode .modal-content {
    background-color: #2c2c2c;
    color: #f4f4f4;
  }
  
  body.dark-mode .modal-close:hover {
    color: #ff5555;
  }
  